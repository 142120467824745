import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const $LOCAL_TOKEN_KEY = 'nexus-local-storage';
export const $LOCAL_REFRESH_TOKEN_KEY = 'nexus-local-storage-refresh-token';
export const $LOCAL_STORAGE_NAME = 'nexus-storage';

export interface UserProp {
  id: number;
  name: string;
  email: string;
  username: string;
  avatar_url?: string;
}

interface AuthStoreProps {
  isAuthenticated: boolean;
  userProp: UserProp;
  accessToken: string;
  refreshToken: string;
  isEnroll?: boolean;
  setAuthenticated: (
    isAuthenticated: boolean,
    userProp?: UserProp,
    accessToken?: string,
    refreshToken?: string
  ) => void;
  setUserProps: (userProp: UserProp) => void;
  setEnroll: (isEnroll: boolean) => void;
  getToken: () => string;
  logout: () => void;
}

const initialState = {
  isAuthenticated: false,
  isEnroll: false,
  token: '',
  accessToken: '',
  refreshToken: '',
  userProp: {} as UserProp,
};

export const useAuthStore = create<AuthStoreProps>()(
  devtools(
    persist(
      (set) => ({
        isAuthenticated: false,
        userProp: {} as UserProp,
        accessToken: '',
        refreshToken: '',
        setAuthenticated: (
          isAuthenticated,
          userProp,
          accessToken: string | undefined,
          refreshToken: string | undefined
        ) => {
          localStorage.setItem($LOCAL_TOKEN_KEY, accessToken!);
          localStorage.setItem($LOCAL_REFRESH_TOKEN_KEY, refreshToken!);
          set({ isAuthenticated, userProp, accessToken, refreshToken });
        },
        setUserProps: (userProp) => {
          set({ userProp });
        },
        getToken: () => {
          return localStorage.getItem($LOCAL_TOKEN_KEY) || '';
        },
        setEnroll: (isEnroll) => {
          set({ isEnroll: isEnroll });
        },
        logout: () => {
          localStorage.clear();
          set((prev) => ({
            ...prev,
            ...initialState,
          }));
        },
      }),
      {
        name: $LOCAL_STORAGE_NAME,
      }
    )
  )
);
